import ORMModel from '@/core/bridge/orm/ORMModel';
import ORMModelExtended from '@/shared/lib/api/ORMModelExtended';

export default class UserV1CorePermissionModel extends ORMModelExtended {
  public static entity = 'users-v1-permissions';

  public static methodConf = {
    http: {
      url: '/v2/users/permissions', // TODO add APP_URL
    },
  };

  public static fields() {
    return {
      id: this.number(null),
      name: this.string(''),
      sort: this.number(null),
    };
  }

  /**
   * Public fields
   */
  public id!: number;
  public name!: string;
  public sort!: number;
}
